.confirmation-paper {
  padding: 20px !important;
  text-align: center !important;
  background-color: #f9f9f9 !important;
  border-radius: 8px !important;
  transition: all 0.3s ease !important;
}

.confirmation-icon-box {
  margin-bottom: 20px !important;
}

.confirmation-icon {
  object-fit: contain !important;
  display: block !important;
  margin: auto !important;
  max-width: 80px !important;
}

.error-icon {
  color: red !important;
}

.confirm-order {
  color: #4caf50 !important;
  font-weight: bold !important;
  font-family: 'Roboto', sans-serif !important;
}

.cancel-order {
  color: red !important;
  font-weight: bold !important;
  font-family: 'Roboto', sans-serif !important;
}

.device-details-paper, .order-details-paper {
  padding: 15px !important;
  margin-top: 20px !important;
  background-color: #ffffff !important;
  border-radius: 8px !important;
  transition: box-shadow 0.3s ease !important;
}

.device-details-paper:hover, .order-details-paper:hover {
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1) !important;
}

.device-image {
  width: 100% !important;
  max-width: 100px !important;
  border-radius: 5px !important;
  padding:10%;
}

.device-info {
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
}

.device-name {
  font-weight: bold !important;
}

.pickup-request-button {
  color: #ffffff !important;
  background-color: #ffa726 !important;
  font-size: 0.6rem !important;
}

.view-report-link {
  color: #1976d2 !important;
  text-decoration: none !important;
  cursor: pointer !important;
  transition: color 0.2s ease !important;
}

.view-report-link:hover {
  color: #1565c0 !important;
}

.cancel-order-button {
  background-color: red !important;
  color: white !important;
  border: none !important;
  transition: background-color 0.3s ease !important;
}

.disable-cancel-order-button {
  background-color: grey !important;
  color: white !important;
  border: none !important;
  transition: background-color 0.3s ease !important;
}

.cancel-order-button:hover {
  background-color: darkred !important;
}

.reschedule-button {
  background-color: #1976d2 !important;
  color: white !important;
  transition: background-color 0.3s ease !important;
}

.reschedule-button:hover {
  background-color: #1565c0 !important;
}
