.footer-container {
    background-color: #f8f8f8;
    padding: 2rem;
    margin-top: 2rem;
  }
  
  .footer-item {
    text-align: center;
  }
  
  .footer-logo img {
    width: 150px;
    margin-bottom: 1rem;
  }
  
  .social-icon {
    color: #555;
    margin: 0 0.5rem;
    transition: color 0.3s ease;
  }
  
  .social-icon:hover {
    color: #0073e6; /* Change color on hover */
  }
  
  .footer-heading {
    font-weight: bold;
    margin-bottom: 1rem;
    color: #333;
  }
  
  .footer-menu {
    padding: 0;
  }
  
  .footer-menu-item {
    padding: 0.25rem 0;
    cursor: pointer;
    font-size: 0.825rem;
    color: #555;
    transition: color 0.3s ease;
  }
  
  .footer-menu-item:hover {
    color: #0073e6; /* Change color on hover */
  }
  
  .footer-copy {
    margin-top: 2rem;
    color: #999;
  }
  