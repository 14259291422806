.MuiToolbar-root {
    background-color: #0d47a1; /* Dark blue background for the toolbar */
    color: white; /* White text color */
}
.MuiButton-root {
    color: white; /* White text for buttons */
    text-transform: none; /* Keep text case as is */
    font-size: 1rem; /* Font size for buttons */
    margin: 0 0.5rem; /* Horizontal spacing between buttons */
    font-weight: bold; /* Bold text */
}

.MuiListItemText-root:hover {
    color: #1565c0; /* Slightly lighter blue on hover */
    text-decoration: underline; /* Underline text on hover */
}
.MuiListItemText-root {
    font-size: 0.95rem; /* Slightly larger font size */
    color: #0d47a1; /* Dark blue text color */
}

.MuiListItem-root:hover {
    background-color: #bbdefb; /* Lighter blue on hover */
    transform: translateX(5px); /* Slight move to the right on hover */
}

.MuiListItem-root {
    border-radius: 5px; /* Rounded corners */
    margin: 0.25rem 0; /* Vertical spacing between list items */
    transition: background-color 0.3s ease, transform 0.3s ease; /* Smooth hover effects */
}
