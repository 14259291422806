/* SearchComponent.css */
.search-container {
  position: relative;
  margin-left: auto;
  max-width: 400px; /* Adjust width as necessary */
}

.search-input .MuiInputBase-root {
  padding-right: 0;
}

.search-input .MuiIconButton-root {
  padding: 0;
}

.popover-content {
  max-height: 300px;
  overflow-y: auto;
  max-width: 400px; /* Adjust width as necessary */

}
@media (max-width: 600px) {
  .search-container {
    position: relative;
    margin-left: 7%;
    max-width: 400px;
    margin-bottom: 3%;
  }
}
